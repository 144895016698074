// BuyRequest.js
// BuyRequest.js
import './styles.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ethers } from 'ethers'; // Import the ethers.js library
import ENFI_TOKEN_ABI from './ENFI_TOKEN_ABI.json'; // Import the ABI for the ENFI token contract
import USDT_TOKEN_ABI from './USDT_TOKEN_ABI.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import enfi from '../assets/brand/genesisMultiverse.png'



function RequestList() {
  const [allRequests, setAllRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [loading, setLoading] = useState(false);      
  const [loadingRecordId, setLoadingRecordId] = useState(null);

                                                                                   
  // Function to connect the wallet
  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });

        if (accounts.length > 0) {
          const walletAddress = accounts[0];
          setWalletAddress(walletAddress);
          // alert(`Connected to wallet: ${walletAddress}`);
          toast.success(`Connected to wallet: ${walletAddress} !`);

          // toast.warning('Please connect your wallet before approving..');

        } else {
          // alert('No Ethereum accounts found in MetaMask.');
          toast.warning('Please install MetaMask !.');

        }
      } else {
        // alert('MetaMask is not installed or not accessible.');
        toast.warning('MetaMask is not installed or not accessible..');

      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
      // alert('Error connecting to wallet. Please try again.');
      toast.warning('Error connecting to wallet. Please try again..');

    }
  };

   // Function to send BNB tokens
   const sendBNBToken = async (recipientAddress, amount, request) => {
    try {
      if (!walletAddress) {
        alert('Please connect your wallet before approving.');
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Send BNB tokens (replace 'YOUR_BNB_TOKEN_ADDRESS' with the actual BNB token contract address)
      const tx = await signer.sendTransaction({
        to: recipientAddress,
        value: ethers.utils.parseEther(amount.toString()), // Convert amount to wei
      });

      await tx.wait(); // Wait for the transaction to be mined
      alert(`Sent ${amount} BNB tokens to ${recipientAddress}`);

      // After sending tokens, make an HTTP POST request to update the request status
      const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
        PK_RequestId: request.PK_RequestId,
        ActionType: 'Update Request',
        RequestStatus: 'Approved',
      });

      if (response.status === 200) {
        alert(`Sent ${amount} BNB tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
        // You may also want to update the request's status in your UI here if applicable.
      } else {
        alert('Error updating request status. Please try again.');
      }
    } catch (error) {
      console.error('Error sending BNB tokens:', error);
      alert('Error sending BNB tokens. Please try again.');
    }
  };

  // Function to send USDT tokens
  const sendUSDTToken = async (recipientAddress, amount, request) => {
    try {
      setLoadingRecordId(request.PK_RequestId); // Set loading state for the clicked record

      setLoading(true);

    // Validate recipient address
    if (!recipientAddress || !ethers.utils.isAddress(recipientAddress)) {
      throw new Error('Invalid recipient address');
    }

    // Validate amount
    if (!amount || isNaN(amount) || amount <= 0) {
      throw new Error('Invalid amount');
    }

    // Validate wallet address
    if (!walletAddress) {
      throw new Error('Wallet address is not connected');
    }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const enfiTokenContract = new ethers.Contract(
        '0x55d398326f99059ff775485246999027b3197955', // USDT token contract address
        USDT_TOKEN_ABI, // ABI for USDT token
        signer
      );

       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
    const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

    const userBalance = await enfiTokenContract.balanceOf(walletAddress);
    const adjustedMinQty = ethers.utils.parseUnits(amount.toString(), 18);
    if (userBalance.lt(adjustedMinQty)) {
      throw new Error('Insufficient USDT balance');
    }


      const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
      await tx.wait(); // Wait for the transaction to be mined
      // alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);


      toast.success(`Sent ${amount} USDT successfully !`);

      // After sending tokens, make an HTTP POST request to update the request status
      const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
        PK_RequestId: request.PK_RequestId,
        ActionType: 'Update Request',
        RequestStatus: 'Approved',
      });

      if (response.status === 201) {
        // alert(`Sent ${amount} USDT tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
        // You may also want to update the request's status in your UI here if applicable.
      } else {
        // alert('Error updating request status. Please try again.');
      }
    } catch (error) {
      console.error('Error sending USDT tokens:', error);
      // alert('Error sending USDT tokens. Please try again.');
      toast.error(`Error sending USDT tokens: ${error.message}`);

    } finally {
      setLoadingRecordId(null); // Clear loading state after processing
      setLoading(false);
    }
  };

  // Function to send ENFI tokens
  const sendENFIToken = async (recipientAddress, amount, request) => {
    try {
      if (!walletAddress) {
        alert('Please connect your wallet before approving.');
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const enfiTokenContract = new ethers.Contract(
        '0x55d398326f99059ff775485246999027b3197955', // ENFI token contract address
        USDT_TOKEN_ABI, // ABI for ENFI token
        signer
      );

      // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
      const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

      const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
      await tx.wait(); // Wait for the transaction to be mined
      alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);

      // After sending tokens, make an HTTP POST request to update the request status
      const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
        PK_RequestId: request.PK_RequestId,
        ActionType: 'Update Request',
        RequestStatus: 'Approved',
      });

      if (response.status === 200) {
        alert(`Sent ${amount} ENFI tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
        // You may also want to update the request's status in your UI here if applicable.
      } else {
        alert('Error updating request status. Please try again.');
      }
    } catch (error) {
      console.error('Error sending ENFI tokens:', error);
      alert('Error sending ENFI tokens. Please try again.');
    }
  };

  useEffect(() => {
    axios.get('https://demoapi.enfipay.com/admin-api/all-requests')
      .then((response) => {
        setAllRequests(response.data);
        setFilteredRequests(response.data);
      })
      .catch((error) => {
        console.error('Error fetching all requests:', error);
      });
  }, []);

  useEffect(() => {
    // console.log("Selected Type:", selectedType);
    // console.log("All Requests:", allRequests);
  
    if (selectedType) {
      const filtered = allRequests.filter((request) => request.RequestType === selectedType);
      // console.log("Filtered Requests:", filtered);
      setFilteredRequests(filtered);
    } else {
      setFilteredRequests(allRequests);
    }
  }, [selectedType, allRequests]);

    const toggleTransactionSelection = (requestId) => {
    setSelectedTransactions((prevSelected) =>
      prevSelected.includes(requestId)
        ? prevSelected.filter((id) => id !== requestId)
        : [...prevSelected, requestId]
    );
  };

  const isSelected = (requestId) => {
    return selectedTransactions.includes(requestId);
  };

  const performSelectedActions = () => {
    selectedTransactions.forEach((requestId) => {
      const request = allRequests.find((r) => r.PK_RequestId === requestId);
      if (selectedType === 'Buy Request') {
        // sendENFIToken(request.WalletAddress, request.EnfiQty, request);
        sendUSDTToken(request.WalletAddress, request.EnfiQty, request);
      } else if (selectedType === 'Withdrawl Request') {
        // sendBNBToken(request.WalletAddress, request.BNBQty, request);
      }
    });
  };

  const handleLogout = () => {
    // Clear local storage data
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/admin-/login/-page';
  };

  return (
    <div>
 
   <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
      <img className="sidebar-brand-full mx-2" src={enfi} height={50} width={185}  />

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
  
  <h4 style={{textAlign:'center' ,marginLeft:'40%',color:'white'}}>Admin Panel</h4>
  </div>
  <form className="form-inline">
     <button onClick={handleLogout} className="btn btn-outline-danger mx-4" type="button">LogOut</button>
   
  </form>
</nav>
    </div>


      <div >
      <button onClick={connectWallet}   style={{  backgroundColor: 'red', color: 'white', padding: '10px 30px',
        borderRadius: '10px', border: 'none', fontSize: '16px', margin: '7px'}}>
        Connect Wallet </button>
        <button
          className={selectedType === null ? 'active-button' : 'inactive-button'}
          onClick={() => setSelectedType(null)}
        >
          Show All Requests
        </button>
        {/* <button
          className={selectedType === 'Buy Request' ? 'active-button' : 'inactive-button'}
          onClick={() => setSelectedType('Buy Request')}
        >
          Buy Request
        </button> */}
        <button
          className={selectedType === 'Withdrawl Request' ? 'active-button' : 'inactive-button'}
          onClick={() => setSelectedType('Withdrawl Request')}
        >
          Withdrawl Request
        </button>
      </div>
      {/* {selectedType && (
        <div>
          <button className="approve-button" onClick={performSelectedActions}>Perform multiple Transaction</button>
        </div>
      )} */}
      <div style={{ overflow: 'auto' }}>
      <table className="custom-table mt-2">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>PK_RequestId</th>
            <th>RequestStatus</th>
            <th>Member Name</th>
            <th>Wallet Address</th>
            <th>Request Type</th>
            <th>Request Quantity</th>
            {/* <th>Quantity</th> */}
            {/* <th>Select</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((request, index) => (
            <tr key={index}>
            <td>{index + 1}</td>
              <td>{request.PK_RequestId}</td>
              <td>{request.RequestStatus}</td>
              <td>{request.MemberName}</td>
              <td>{request.WalletAddress}</td>
              <td>{request.RequestType}</td>
              <td>{request.RequestQty}</td>
              {/* <td>
                {request.RequestType === 'Buy Request' ? `${request.EnfiQty} ENFI` : `${request.BNBQty} BNB` }
              </td> */}
              {/* <td>
                {selectedType && (
                  <input
                    type="checkbox"
                    onChange={() => toggleTransactionSelection(request.PK_RequestId)}
                    checked={isSelected(request.PK_RequestId)}
                  />
                )}
              </td> */}
              <td>
                {/* {selectedType === 'Buy Request' && request.RequestStatus !== 'Approved' && (
                  <button
                    className="approve-button"
                    onClick={() => sendUSDTToken(request.WalletAddress, request.EnfiQty, request)}
                  >
                    Approve & Send USDT1
                  </button>
                )} */}
            
                {selectedType === 'Withdrawl Request' && request.RequestStatus !== 'Approved' && (
                  <button
        className="approve-button"
        onClick={() => sendUSDTToken(request.WalletAddress, request.RequestQty, request)}
        disabled={loading} 
      >
{loading && loadingRecordId === request.PK_RequestId && (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
)}


      Approve & Send USDT
      </button>
      )}
                {request.RequestStatus === 'Approved' && (
                  <span>Request Approved</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
    
    </div>
  );
}
export default RequestList;












































































// // BuyRequest.js
// import './styles.css';
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ethers } from 'ethers'; // Import the ethers.js library
// import ENFI_TOKEN_ABI from './ENFI_TOKEN_ABI.json'; // Import the ABI for the ENFI token contract
// import USDT_TOKEN_ABI from './USDT_TOKEN_ABI.json'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import enfi from '../assets/brand/genesisMultiverse.png'



// function RequestList() {
//   const [allRequests, setAllRequests] = useState([]);
//   const [filteredRequests, setFilteredRequests] = useState([]);
//   const [selectedType, setSelectedType] = useState(null);
//   const [walletAddress, setWalletAddress] = useState(null);
//   const [selectedTransactions, setSelectedTransactions] = useState([]);
//   const [loading, setLoading] = useState(false);      
//   const [loadingRecordId, setLoadingRecordId] = useState(null);

                                                                                   
//   // Function to connect the wallet
//   const connectWallet = async () => {
//     try {
//       if (window.ethereum) {
//         await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const accounts = await window.ethereum.request({ method: 'eth_accounts' });

//         if (accounts.length > 0) {
//           const walletAddress = accounts[0];
//           setWalletAddress(walletAddress);
//           // alert(`Connected to wallet: ${walletAddress}`);
//           toast.success(`Connected to wallet: ${walletAddress} !`);

//           // toast.warning('Please connect your wallet before approving..');

//         } else {
//           // alert('No Ethereum accounts found in MetaMask.');
//           toast.warning('Please install MetaMask !.');

//         }
//       } else {
//         // alert('MetaMask is not installed or not accessible.');
//         toast.warning('MetaMask is not installed or not accessible..');

//       }
//     } catch (error) {
//       console.error('Error connecting to wallet:', error);
//       // alert('Error connecting to wallet. Please try again.');
//       toast.warning('Error connecting to wallet. Please try again..');

//     }
//   };

//    // Function to send BNB tokens
//    const sendBNBToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();

//       // Send BNB tokens (replace 'YOUR_BNB_TOKEN_ADDRESS' with the actual BNB token contract address)
//       const tx = await signer.sendTransaction({
//         to: recipientAddress,
//         value: ethers.utils.parseEther(amount.toString()), // Convert amount to wei
//       });

//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} BNB tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} BNB tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending BNB tokens:', error);
//       alert('Error sending BNB tokens. Please try again.');
//     }
//   };

//   // Function to send USDT tokens
//   const sendUSDTToken = async (recipientAddress, amount, request) => {
//     try {
//       setLoadingRecordId(request.PK_RequestId); // Set loading state for the clicked record

//       setLoading(true);

//     // Validate recipient address
//     if (!recipientAddress || !ethers.utils.isAddress(recipientAddress)) {
//       throw new Error('Invalid recipient address');
//     }

//     // Validate amount
//     if (!amount || isNaN(amount) || amount <= 0) {
//       throw new Error('Invalid amount');
//     }

//     // Validate wallet address
//     if (!walletAddress) {
//       throw new Error('Wallet address is not connected');
//     }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // USDT token contract address
//         USDT_TOKEN_ABI, // ABI for USDT token
//         signer
//       );

//        // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//     const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//     const userBalance = await enfiTokenContract.balanceOf(walletAddress);
//     const adjustedMinQty = ethers.utils.parseUnits(amount.toString(), 18);
//     if (userBalance.lt(adjustedMinQty)) {
//       throw new Error('Insufficient USDT balance');
//     }


//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       // alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);


//       toast.success(`Sent ${amount} USDT successfully !`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 201) {
//         // alert(`Sent ${amount} USDT tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         // alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending USDT tokens:', error);
//       // alert('Error sending USDT tokens. Please try again.');
//       toast.error(`Error sending USDT tokens: ${error.message}`);

//     } finally {
//       setLoadingRecordId(null); // Clear loading state after processing
//       setLoading(false);
//     }
//   };

//   // Function to send ENFI tokens
//   const sendENFIToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // ENFI token contract address
//         USDT_TOKEN_ABI, // ABI for ENFI token
//         signer
//       );

//       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//       const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} ENFI tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending ENFI tokens:', error);
//       alert('Error sending ENFI tokens. Please try again.');
//     }
//   };

//   useEffect(() => {
//     axios.get('https://demoapi.enfipay.com/admin-api/all-requests')
//       .then((response) => {
//         setAllRequests(response.data);
//         setFilteredRequests(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching all requests:', error);
//       });
//   }, []);

//   useEffect(() => {
//     // console.log("Selected Type:", selectedType);
//     // console.log("All Requests:", allRequests);
  
//     if (selectedType) {
//       const filtered = allRequests.filter((request) => request.RequestType === selectedType);
//       // console.log("Filtered Requests:", filtered);
//       setFilteredRequests(filtered);
//     } else {
//       setFilteredRequests(allRequests);
//     }
//   }, [selectedType, allRequests]);

//     const toggleTransactionSelection = (requestId) => {
//     setSelectedTransactions((prevSelected) =>
//       prevSelected.includes(requestId)
//         ? prevSelected.filter((id) => id !== requestId)
//         : [...prevSelected, requestId]
//     );
//   };

//   const isSelected = (requestId) => {
//     return selectedTransactions.includes(requestId);
//   };

//   const performSelectedActions = () => {
//     selectedTransactions.forEach((requestId) => {
//       const request = allRequests.find((r) => r.PK_RequestId === requestId);
//       if (selectedType === 'Buy Request') {
//         // sendENFIToken(request.WalletAddress, request.EnfiQty, request);
//         sendUSDTToken(request.WalletAddress, request.EnfiQty, request);
//       } else if (selectedType === 'Withdrawl Request') {
//         // sendBNBToken(request.WalletAddress, request.BNBQty, request);
//       }
//     });
//   };

//   const handleLogout = () => {
//     // Clear local storage data
//     localStorage.clear();

//     // Redirect to the login page
//     window.location.href = '/admin-/login/-page';
//   };

//   return (
//     <div>
 
//    <div>
//       <nav className="navbar navbar-expand-lg navbar-light bg-dark">
//       <img className="sidebar-brand-full mx-2" src={enfi} height={50} width={185}  />

//   <div className="collapse navbar-collapse" id="navbarSupportedContent">
  
//   <h4 style={{textAlign:'center' ,marginLeft:'40%',color:'white'}}>Admin Panel</h4>
//   </div>
//   <form className="form-inline">
//      <button onClick={handleLogout} className="btn btn-outline-danger mx-4" type="button">LogOut</button>
   
//   </form>
// </nav>
//     </div>


//       <div >
//       <button onClick={connectWallet}   style={{  backgroundColor: 'red', color: 'white', padding: '10px 30px',
//         borderRadius: '10px', border: 'none', fontSize: '16px', margin: '7px'}}>
//         Connect Wallet </button>
//         <button
//           className={selectedType === null ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType(null)}
//         >
//           Show All Requests
//         </button>
//         {/* <button
//           className={selectedType === 'Buy Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Buy Request')}
//         >
//           Buy Request
//         </button> */}
//         <button
//           className={selectedType === 'Withdrawl Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Withdrawl Request')}
//         >
//           Withdrawl Request
//         </button>
//       </div>
//       {/* {selectedType && (
//         <div>
//           <button className="approve-button" onClick={performSelectedActions}>Perform multiple Transaction</button>
//         </div>
//       )} */}
//       <div style={{ overflow: 'auto' }}>
//       <table className="custom-table mt-2">
//         <thead>
//           <tr>
//             <th>Sr No.</th>
//             <th>PK_RequestId</th>
//             <th>RequestStatus</th>
//             <th>Member Name</th>
//             <th>Wallet Address</th>
//             <th>Request Type</th>
//             <th>Request Quantity</th>
//             {/* <th>Quantity</th> */}
//             {/* <th>Select</th> */}
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredRequests.map((request, index) => (
//             <tr key={index}>
//             <td>{index + 1}</td>
//               <td>{request.PK_RequestId}</td>
//               <td>{request.RequestStatus}</td>
//               <td>{request.MemberName}</td>
//               <td>{request.WalletAddress}</td>
//               <td>{request.RequestType}</td>
//               <td>{request.RequestQty}</td>
//               {/* <td>
//                 {request.RequestType === 'Buy Request' ? `${request.EnfiQty} ENFI` : `${request.BNBQty} BNB` }
//               </td> */}
//               {/* <td>
//                 {selectedType && (
//                   <input
//                     type="checkbox"
//                     onChange={() => toggleTransactionSelection(request.PK_RequestId)}
//                     checked={isSelected(request.PK_RequestId)}
//                   />
//                 )}
//               </td> */}
//               <td>
//                 {/* {selectedType === 'Buy Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//                     className="approve-button"
//                     onClick={() => sendUSDTToken(request.WalletAddress, request.EnfiQty, request)}
//                   >
//                     Approve & Send USDT1
//                   </button>
//                 )} */}
            
//                 {selectedType === 'Withdrawl Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//         className="approve-button"
//         onClick={() => sendUSDTToken(request.WalletAddress, request.RequestQty, request)}
//         disabled={loading} 
//       >
// {loading && loadingRecordId === request.PK_RequestId && (
//   <div className="loader-container">
//     <div className="loader"></div>
//   </div>
// )}


//       Approve & Send USDT
//       </button>
//       )}
//                 {request.RequestStatus === 'Approved' && (
//                   <span>Request Approved</span>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <ToastContainer />
//     </div>
    
//     </div>
//   );
// }
// export default RequestList;












































// import './styles.css';
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ethers } from 'ethers'; // Import the ethers.js library
// import ENFI_TOKEN_ABI from './ENFI_TOKEN_ABI.json'; // Import the ABI for the ENFI token contract
// import USDT_TOKEN_ABI from './USDT_TOKEN_ABI.json'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import enfi from '../assets/brand/genesisMultiverse.png'



// function RequestList() {
//   const [allRequests, setAllRequests] = useState([]);
//   const [filteredRequests, setFilteredRequests] = useState([]);
//   const [selectedType, setSelectedType] = useState(null);
//   const [walletAddress, setWalletAddress] = useState(null);
//   const [selectedTransactions, setSelectedTransactions] = useState([]);
//   const [loading, setLoading] = useState(false);      
                                                                                   
//   // Function to connect the wallet
//   const connectWallet = async () => {
//     try {
//       if (window.ethereum) {
//         await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const accounts = await window.ethereum.request({ method: 'eth_accounts' });

//         if (accounts.length > 0) {
//           const walletAddress = accounts[0];
//           setWalletAddress(walletAddress);
//           // alert(`Connected to wallet: ${walletAddress}`);
//           toast.success(`Connected to wallet: ${walletAddress} !`);

//           // toast.warning('Please connect your wallet before approving..');

//         } else {
//           // alert('No Ethereum accounts found in MetaMask.');
//           toast.warning('Please install MetaMask !.');

//         }
//       } else {
//         // alert('MetaMask is not installed or not accessible.');
//         toast.warning('MetaMask is not installed or not accessible..');

//       }
//     } catch (error) {
//       console.error('Error connecting to wallet:', error);
//       // alert('Error connecting to wallet. Please try again.');
//       toast.warning('Error connecting to wallet. Please try again..');

//     }
//   };

//    // Function to send BNB tokens
//    const sendBNBToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();

//       // Send BNB tokens (replace 'YOUR_BNB_TOKEN_ADDRESS' with the actual BNB token contract address)
//       const tx = await signer.sendTransaction({
//         to: recipientAddress,
//         value: ethers.utils.parseEther(amount.toString()), // Convert amount to wei
//       });

//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} BNB tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} BNB tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending BNB tokens:', error);
//       alert('Error sending BNB tokens. Please try again.');
//     }
//   };

//   // Function to send USDT tokens
//   const sendUSDTToken = async (recipientAddress, amount, request) => {
//     try {
//       setLoading(true);

//       if (!walletAddress) {
//         // alert('Please connect your wallet before approving.');
//         toast.warning('Please connect your wallet before approving..');

//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // USDT token contract address
//         USDT_TOKEN_ABI, // ABI for USDT token
//         signer
//       );

//       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//       const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       // alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);


//       toast.success(`Sent ${amount} USDT successfully !`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 201) {
//         // alert(`Sent ${amount} USDT tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         // alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending USDT tokens:', error);
//       // alert('Error sending USDT tokens. Please try again.');
//       toast.success('Error sending USDT tokens. Please try again !');

//     } finally {
//       setLoading(false);
//     }
//   };

//   // Function to send ENFI tokens
//   const sendENFIToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // ENFI token contract address
//         USDT_TOKEN_ABI, // ABI for ENFI token
//         signer
//       );

//       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//       const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} ENFI tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending ENFI tokens:', error);
//       alert('Error sending ENFI tokens. Please try again.');
//     }
//   };

//   useEffect(() => {
//     axios.get('https://demoapi.enfipay.com/admin-api/all-requests')
//       .then((response) => {
//         setAllRequests(response.data);
//         setFilteredRequests(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching all requests:', error);
//       });
//   }, []);

//   useEffect(() => {
//     // console.log("Selected Type:", selectedType);
//     // console.log("All Requests:", allRequests);
  
//     if (selectedType) {
//       const filtered = allRequests.filter((request) => request.RequestType === selectedType);
//       // console.log("Filtered Requests:", filtered);
//       setFilteredRequests(filtered);
//     } else {
//       setFilteredRequests(allRequests);
//     }
//   }, [selectedType, allRequests]);

//     const toggleTransactionSelection = (requestId) => {
//     setSelectedTransactions((prevSelected) =>
//       prevSelected.includes(requestId)
//         ? prevSelected.filter((id) => id !== requestId)
//         : [...prevSelected, requestId]
//     );
//   };

//   const isSelected = (requestId) => {
//     return selectedTransactions.includes(requestId);
//   };

//   const performSelectedActions = () => {
//     selectedTransactions.forEach((requestId) => {
//       const request = allRequests.find((r) => r.PK_RequestId === requestId);
//       if (selectedType === 'Buy Request') {
//         // sendENFIToken(request.WalletAddress, request.EnfiQty, request);
//         sendUSDTToken(request.WalletAddress, request.EnfiQty, request);
//       } else if (selectedType === 'Withdrawl Request') {
//         // sendBNBToken(request.WalletAddress, request.BNBQty, request);
//       }
//     });
//   };

//   const handleLogout = () => {
//     // Clear local storage data
//     localStorage.clear();

//     // Redirect to the login page
//     window.location.href = '/admin-/login/-page';
//   };

//   return (
//     <div>
 
//    <div>
//       <nav className="navbar navbar-expand-lg navbar-light bg-dark">
//       <img className="sidebar-brand-full mx-2" src={enfi} height={50} width={185}  />

//   <div className="collapse navbar-collapse" id="navbarSupportedContent">
  
//   <h4 style={{textAlign:'center' ,marginLeft:'40%',color:'white'}}>Admin Panel</h4>
//   </div>
//   <form className="form-inline">
//      <button onClick={handleLogout} className="btn btn-outline-danger mx-4" type="button">LogOut</button>
   
//   </form>
// </nav>
//     </div>


//       <div >
//       <button onClick={connectWallet}   style={{  backgroundColor: 'red', color: 'white', padding: '10px 30px',
//         borderRadius: '10px', border: 'none', fontSize: '16px', margin: '7px'}}>
//         Connect Wallet </button>
//         <button
//           className={selectedType === null ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType(null)}
//         >
//           Show All Requests
//         </button>
//         {/* <button
//           className={selectedType === 'Buy Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Buy Request')}
//         >
//           Buy Request
//         </button> */}
//         <button
//           className={selectedType === 'Withdrawl Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Withdrawl Request')}
//         >
//           Withdrawl Request
//         </button>
//       </div>
//       {selectedType && (
//         <div>
//           <button className="approve-button" onClick={performSelectedActions}>Perform multiple Transaction</button>
//         </div>
//       )}
//       <div style={{ overflow: 'auto' }}>
//       <table className="custom-table mt-2">
//         <thead>
//           <tr>
//             <th>Sr No.</th>
//             <th>PK_RequestId</th>
//             <th>RequestStatus</th>
//             <th>Member Name</th>
//             <th>Wallet Address</th>
//             <th>Request Type</th>
//             <th>Request Quantity</th>
//             {/* <th>Quantity</th> */}
//             <th>Select</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredRequests.map((request, index) => (
//             <tr key={index}>
//             <td>{index + 1}</td>
//               <td>{request.PK_RequestId}</td>
//               <td>{request.RequestStatus}</td>
//               <td>{request.MemberName}</td>
//               <td>{request.WalletAddress}</td>
//               <td>{request.RequestType}</td>
//               <td>{request.RequestQty}</td>
//               {/* <td>
//                 {request.RequestType === 'Buy Request' ? `${request.EnfiQty} ENFI` : `${request.BNBQty} BNB` }
//               </td> */}
//               <td>
//                 {selectedType && (
//                   <input
//                     type="checkbox"
//                     onChange={() => toggleTransactionSelection(request.PK_RequestId)}
//                     checked={isSelected(request.PK_RequestId)}
//                   />
//                 )}
//               </td>
//               <td>
//                 {selectedType === 'Buy Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//                     className="approve-button"
//                     onClick={() => sendUSDTToken(request.WalletAddress, request.EnfiQty, request)}
//                   >
//                     Approve & Send USDT1
//                   </button>
//                 )}
            
//                 {selectedType === 'Withdrawl Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//         className="approve-button"
//         onClick={() => sendUSDTToken(request.WalletAddress, request.RequestQty, request)}
//         disabled={loading} 
//       >
//         {loading && <div style={{ display: 'inline-block', width: '1.5rem', height: '1.5rem',color:'yellow',marginRight:'40px', borderTop: '0.155em solid red', borderRight: '0.155em solid blue', borderBottom: '0.155em solid yellow', borderLeft: '0.155em solid green', borderRadius: '50%', animation: 'spinner-border 0.65s linear infinite' }}></div>}
//         Approve & Send USDT
//       </button>
//       )}
//                 {request.RequestStatus === 'Approved' && (
//                   <span>Request Approved</span>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <ToastContainer />
//     </div>
    
//     </div>
//   );
// }
// export default RequestList;























// // BuyRequest.js
// import './styles.css';
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ethers } from 'ethers'; // Import the ethers.js library
// import ENFI_TOKEN_ABI from './ENFI_TOKEN_ABI.json'; // Import the ABI for the ENFI token contract
// import USDT_TOKEN_ABI from './USDT_TOKEN_ABI.json'
// import AdminNav from './AdminNav.js'

// function RequestList() {
//   const [allRequests, setAllRequests] = useState([]);
//   const [filteredRequests, setFilteredRequests] = useState([]);
//   const [selectedType, setSelectedType] = useState(null);
//   const [walletAddress, setWalletAddress] = useState(null);
//   const [selectedTransactions, setSelectedTransactions] = useState([]);
      
                                                                   
//   // Function to connect the wallet
//   const connectWallet = async () => {
//     try {
//       if (window.ethereum) {
//         await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const accounts = await window.ethereum.request({ method: 'eth_accounts' });

//         if (accounts.length > 0) {
//           const walletAddress = accounts[0];
//           setWalletAddress(walletAddress);
//           alert(`Connected to wallet: ${walletAddress}`);
//         } else {
//           alert('No Ethereum accounts found in MetaMask.');
//         }
//       } else {
//         alert('MetaMask is not installed or not accessible.');
//       }
//     } catch (error) {
//       console.error('Error connecting to wallet:', error);
//       alert('Error connecting to wallet. Please try again.');
//     }
//   };

//    // Function to send BNB tokens
//    const sendBNBToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();

//       // Send BNB tokens (replace 'YOUR_BNB_TOKEN_ADDRESS' with the actual BNB token contract address)
//       const tx = await signer.sendTransaction({
//         to: recipientAddress,
//         value: ethers.utils.parseEther(amount.toString()), // Convert amount to wei
//       });

//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} BNB tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} BNB tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending BNB tokens:', error);
//       alert('Error sending BNB tokens. Please try again.');
//     }
//   };

//   // Function to send USDT tokens
//   const sendUSDTToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // USDT token contract address
//         USDT_TOKEN_ABI, // ABI for USDT token
//         signer
//       );

//       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//       const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} USDT tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending USDT tokens:', error);
//       alert('Error sending USDT tokens. Please try again.');
//     }
//   };

//   // Function to send ENFI tokens
//   const sendENFIToken = async (recipientAddress, amount, request) => {
//     try {
//       if (!walletAddress) {
//         alert('Please connect your wallet before approving.');
//         return;
//       }

//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const signer = provider.getSigner();
//       const enfiTokenContract = new ethers.Contract(
//         '0x55d398326f99059ff775485246999027b3197955', // ENFI token contract address
//         USDT_TOKEN_ABI, // ABI for ENFI token
//         signer
//       );

//       // Convert the decimal amount to BigNumber with proper precision (e.g., 18 decimal places)
//       const amountToSend = ethers.utils.parseUnits(amount.toString(), 18);

//       const tx = await enfiTokenContract.transfer(recipientAddress, amountToSend);
//       await tx.wait(); // Wait for the transaction to be mined
//       alert(`Sent ${amount} ENFI tokens to ${recipientAddress}`);

//       // After sending tokens, make an HTTP POST request to update the request status
//       const response = await axios.post('https://demoapi.enfipay.com/admin-api/admin-approve-request', {
//         PK_RequestId: request.PK_RequestId,
//         ActionType: 'Update Request',
//         RequestStatus: 'Approved',
//       });

//       if (response.status === 200) {
//         alert(`Sent ${amount} ENFI tokens to ${recipientAddress} for request ${request.PK_RequestId}. Request approved.`);
        
//         // You may also want to update the request's status in your UI here if applicable.
//       } else {
//         alert('Error updating request status. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error sending ENFI tokens:', error);
//       alert('Error sending ENFI tokens. Please try again.');
//     }
//   };

//   useEffect(() => {
//     axios.get('https://demoapi.enfipay.com/admin-api/all-requests')
//       .then((response) => {
//         setAllRequests(response.data);
//         setFilteredRequests(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching all requests:', error);
//       });
//   }, []);

//   useEffect(() => {
//     // console.log("Selected Type:", selectedType);
//     // console.log("All Requests:", allRequests);
  
//     if (selectedType) {
//       const filtered = allRequests.filter((request) => request.RequestType === selectedType);
//       // console.log("Filtered Requests:", filtered);
//       setFilteredRequests(filtered);
//     } else {
//       setFilteredRequests(allRequests);
//     }
//   }, [selectedType, allRequests]);


//     const toggleTransactionSelection = (requestId) => {
//     setSelectedTransactions((prevSelected) =>
//       prevSelected.includes(requestId)
//         ? prevSelected.filter((id) => id !== requestId)
//         : [...prevSelected, requestId]
//     );
//   };

//   const isSelected = (requestId) => {
//     return selectedTransactions.includes(requestId);
//   };

//   const performSelectedActions = () => {
//     selectedTransactions.forEach((requestId) => {
//       const request = allRequests.find((r) => r.PK_RequestId === requestId);
//       if (selectedType === 'Buy Request') {
//         // sendENFIToken(request.WalletAddress, request.EnfiQty, request);
//         sendUSDTToken(request.WalletAddress, request.EnfiQty, request);
//       } else if (selectedType === 'Withdrawl Request') {
//         // sendBNBToken(request.WalletAddress, request.BNBQty, request);
//       }
//     });
//   };

//   return (
//     <div>
//      <AdminNav />
    
//       <button onClick={connectWallet}   style={{  backgroundColor: 'red', color: 'white', padding: '10px 30px',
//         borderRadius: '10px', border: 'none', fontSize: '16px', margin: '7px'}}>
//         Connect Wallet </button>
//       <div>
//         <button
//           className={selectedType === null ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType(null)}
//         >
//           Show All Requests
//         </button>
//         <button
//           className={selectedType === 'Buy Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Buy Request')}
//         >
//           Buy Request
//         </button>
//         <button
//           className={selectedType === 'Withdrawl Request' ? 'active-button' : 'inactive-button'}
//           onClick={() => setSelectedType('Withdrawl Request')}
//         >
//           Withdrawl Request
//         </button>
//       </div>
//       {selectedType && (
//         <div>
//           <button className="approve-button" onClick={performSelectedActions}>Perform multiple Transaction</button>
//         </div>
//       )}
//       <table>
//         <thead>
//           <tr>
//             <th>Sr No.</th>
//             <th>PK_RequestId</th>
//             <th>RequestStatus</th>
//             <th>Member Name</th>
//             <th>Wallet Address</th>
//             <th>Request Type</th>
//             <th>Request Quantity</th>
//             <th>Quantity</th>
//             <th>Select</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredRequests.map((request, index) => (
//             <tr key={index}>
//             <td>{index + 1}</td>
//               <td>{request.PK_RequestId}</td>
//               <td>{request.RequestStatus}</td>
//               <td>{request.MemberName}</td>
//               <td>{request.WalletAddress}</td>
//               <td>{request.RequestType}</td>
//               <td>{request.RequestQty}</td>
//               <td>
//                 {request.RequestType === 'Buy Request' ? `${request.EnfiQty} ENFI` : `${request.BNBQty} BNB` }
//               </td>
//               <td>
//                 {selectedType && (
//                   <input
//                     type="checkbox"
//                     onChange={() => toggleTransactionSelection(request.PK_RequestId)}
//                     checked={isSelected(request.PK_RequestId)}
//                   />
//                 )}
//               </td>
//               <td>
//                 {selectedType === 'Buy Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//                     className="approve-button"
//                     onClick={() => sendENFIToken(request.WalletAddress, request.EnfiQty, request)}
//                   >
//                     Approve & Send ENFI
//                   </button>
//                 )}
//                 {selectedType === 'Withdrawl Request' && request.RequestStatus !== 'Approved' && (
//                   <button
//                     className="approve-button"
//                     onClick={() => sendUSDTToken(request.WalletAddress, request.BNBQty, request)}
//                   >
//                     Approve & Send USDT
//                   </button>
//                 )}
//                 {request.RequestStatus === 'Approved' && (
//                   <span>Request Approved</span>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
// export default RequestList;
