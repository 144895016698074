//App.js
import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss';
import AuthGuard from './private/AuthGuard'; // Import the AuthGuard component.
import AdminAuthGuard from './private/AdminAuthGuard';
// admin Panel
import AdminLogin from './admin/AdminLogin';
// import AdminNav from './admin/AdminNav';
import BuyRequests from './admin/BuyRequests';
import LogOut from './views/pages/logOut/LogOut';
// import Approve from './components/admin/Approve';




const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))



// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Login1 = React.lazy(() => import('./views/pages/login1/Login1'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const WelcomeMessage = React.lazy(() => import('./views/pages/register/WelcomeMessage'))
const BuyPackage = React.lazy(() => import('./views/pages/buyPackage/BuyPackage'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
 
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/login" name="Login1 Page" element={<Login1 />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/welcome" name="Welcome Page" element={<WelcomeMessage/>} />
            <Route exact path="/buy-/package" name="BuyPackage Page" element={<BuyPackage/>} />
            <Route exact path="/forgot" name="ForgotPassword Page" element={<ForgotPassword />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/user-logout" name="logout page" element={<LogOut/>} />
           {/* Use the AuthGuard to protect all other routes */}
           <Route path="*" element={<AuthGuard> <DefaultLayout /> </AuthGuard>} />


         {/* Admin Panel */}
         <Route path="/admin-/login/-page" element={<AdminLogin />} />
         {/* <Route path="/admin-connect-wallet" element={<AdminNav />} /> */}
         <Route path="/admin/all/requests" element={<AdminAuthGuard> <BuyRequests /> </AdminAuthGuard>} />
         {/* <Route path="/approve-requests" element={<Approve />} /> */}


          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
